import React, { useState, useEffect } from "react"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import BlogPostItem from "../components/blogPostItem"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import {
  ListGroup,
  Row,
  Col,
  Button,
  Card,
  Link,
  Container,
} from "react-bootstrap"
import Banner from "../components/banner"
import ScoreCard from "../components/scoreCard"

const LatestPosts = ({ pageLinks = [], images = [] }) => {
  const [sortedPosts, setSortedPosts] = useState(pageLinks)
  let reviewPosts =
    pageLinks
      .map(elem => {
        return {
          ...elem,
          parsedReview: JSON.parse(elem["post"]["review"]),
        }
      })
      ?.filter(item => item["post"]["review"]?.length > 5) || []

  useEffect(() => {
    if (reviewPosts?.length > 0) {
      reviewPosts = reviewPosts
        .sort((a, b) => {
          return (
            a["parsedReview"]["review_overall_score"] -
            b["parsedReview"]["review_overall_score"]
          )
        })
        .reverse()
        ?.slice(0, 4)

      setSortedPosts(reviewPosts)
    }
  }, [])

  return (
    <Card className="mb-3">
      <Banner>
        <span className="text-white text-uppercase">Recently Tested</span>
      </Banner>
      <Card.Body className="p-0">
        <ListGroup variant="flush">
          {sortedPosts?.map((item, idx) => (
            <ListGroup.Item key={`scoreItem${idx + 1}`}>
              <BlogPostItem
                {...item["post"]}
                images={images}
                key={uuidv4()}
                variant={"sidebar"}
              />
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  )
}

const Index = ({ data }) => {
  const posts = data?.allWpPost?.["posts"] || []

  const postsPerPage = data?.wp?.readingSettings?.postsPerPage || 20

  const [activePosts, setActivePosts] = useState([])
  const [activePostsIndex, setActivePostsIndex] = useState(1)

  useEffect(() => {
    setActivePosts([...posts.slice(0, activePostsIndex * postsPerPage)])
  }, [])

  const handleLoadMore = e => {
    e.preventDefault()

    if (posts.length > (activePostsIndex + 1) * postsPerPage) {
      setActivePosts([...posts.slice(0, (activePostsIndex + 1) * postsPerPage)])
      setActivePostsIndex(activePostsIndex + 1)
    } else {
      setActivePosts(posts)
    }
  }

  return (
    <Layout isHomePage={true} data={data}>
      <Seo title="Home Page" />

      {posts.length > 0 ? (
        <div className="blogposts">
          {activePosts?.length > 0 ? (
            <div className="blogposts__firstrow">
              <BlogPostItem
                {...(activePosts?.[0]?.["post"] ?? {})}
                images={data.allFile.images}
                key={uuidv4()}
                variant={"hero"}
              />

              {posts?.length > 0 ? (
                <LatestPosts pageLinks={posts} images={data.allFile.images} />
              ) : null}
            </div>
          ) : null}
          <div className="blogposts__otherrow">
            {activePosts.map((postObj, idx) => {
              return idx > 0 ? (
                <BlogPostItem
                  {...postObj?.["post"]}
                  images={data.allFile.images}
                  key={uuidv4()}
                  variant={idx > 0 && idx < 10 ? "primary" : "primary"}
                />
              ) : null
            })}
          </div>
        </div>
      ) : null}

      {posts.length > 0 ? (
        <Container>
          <Row>
            <Col sm={12} className="text-center pt-3 pb-3">
              {activePosts.length !== posts.length ? (
                <Button size="md" className="loadmore" onClick={handleLoadMore}>
                  Load More
                </Button>
              ) : null}
            </Col>
          </Row>
        </Container>
      ) : null}
    </Layout>
  )
}

export default React.memo(Index)

export const pageQuery = graphql`
  query GetAllWPPosts {
    allWpPost(sort: { fields: date, order: DESC }) {
      posts: edges {
        post: node {
          id
          review
          title
          uri
          featuredImage {
            node {
              localFile {
                id
                childImageSharp {
                  gatsbyImageData(quality: 10, width: 500)
                }
              }
            }
          }
          databaseId
          excerpt
        }
      }
    }

    wp {
      readingSettings {
        postsPerPage
      }
    }

    allFile {
      images: edges {
        node {
          id
          url
          childImageSharp {
            fixed(grayscale: true) {
              base64
              tracedSVG
              aspectRatio
              srcWebp
              srcSetWebp
              originalName
            }
            gatsbyImageData(quality: 50, width: 768)
          }
        }
      }
    }
  }
`
