import React, { Fragment } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// featuredImage

const ImagePlaceholder = ({ featuredImage = null }) => {
  const img = getImage(featuredImage?.node?.localFile?.childImageSharp)

  return (
    <Fragment>
      {img ? (
        <GatsbyImage
          image={img}
          alt="Blog Placeholder"
          placeholder="blurred"
          className="coverImage"
        />
      ) : (
        <div className="placeholder-fallback">N/A</div>
      )}
    </Fragment>
  )
}

export default ImagePlaceholder
