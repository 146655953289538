import React, { Fragment } from "react"
import parse from "html-react-parser"
import ImagePlaceholder from "../components/imagePlaceholder"
import ScoreCard from "../components/scoreCard"
import { ListGroup, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import Card from "react-bootstrap/Card"

const BlogPostItem = ({
  title = "",
  uri = "#",
  featuredImage = null,
  review = {},
  images,
  databaseId,
  excerpt = "",
  variant = "hero" | "primary" | "secondary" | "sidebar",
}) => {
  const reviewData = JSON.parse(review)

  const imageObj = images.filter(image => {
    const regex = /(.*)\/uploads/gm

    if (reviewData?.["review_image"] && image?.["node"]?.["url"]) {
      return reviewData["review_image"].includes(
        image?.["node"]?.["url"].replace(regex, "")
      )
    } else {
      return false
    }
  })

  return (
    <div
      className={`pl-0 pr-0 blogPostItem ${
        variant === "hero"
          ? "blogPostItem--hero"
          : variant === "primary"
          ? "blogPostItem--primary"
          : variant === "secondary"
          ? "blogPostItem--secondary"
          : "blogPostItem--sidebar"
      }`}
    >
      {variant !== "sidebar" ? (
        <Link to={`${uri}`}>
          {reviewData !== null &&
          Object.keys(reviewData).length > 0 &&
          reviewData?.review_overall_score > 0.1 ? (
            <ScoreCard
              {...{
                filterImage: imageObj?.[0],
                overallScore: reviewData?.["review_overall_score"],
                title,
                uri,
              }}
            />
          ) : (
            <ImagePlaceholder featuredImage={featuredImage} />
          )}
        </Link>
      ) : null}

      {variant !== "sidebar" ? (
        <div className="blogPostItem__text">
          <Link to={uri} className="text-decoration-none">
            <h6 className="font-weight-medium mb-3 py-3">{title}</h6>
          </Link>
        </div>
      ) : null}

      {variant === "sidebar" ? (
        <Link to={`${uri}`}>
          {reviewData?.review_overall_score > 0.1 ? (
            <ScoreCard
              {...{
                filterImage: imageObj?.[0],
                overallScore: reviewData?.["review_overall_score"],
                title,
                uri,
              }}
            />
          ) : null}

          <p className="font-weight-medium">{title.slice(0, 40)}...</p>
        </Link>
      ) : null}
    </div>
  )
}

export default BlogPostItem
